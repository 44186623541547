$link-color: #3cb0fd;

html {
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;

  &.centered-content {
    align-items: center;
    align-content: center;
  }
}

a {
  color: $link-color;
  text-decoration: none;
}

img {
  width: 100% !important;
  height: auto !important;
}

#root {
  width: 100%;
}

.centered-content {
  main {
    margin: 75px auto 0 !important;
  }
}

p {
  width: 100%;
}

table {
  max-width: 100%;
  overflow-x: auto;
  display: block;
  border-collapse: collapse;
  border: 0;

  tr {
    border-bottom: 1px solid #ccc;
  }

  td,
  th {
    border: 0;
  }
}

.button--upload-link-replacement{
  color: $link-color;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  a {
    display: none;
  }
}

/* Internet Exploder */
main {
  display: block;
}

p.MuiFormHelperText-contained {
  width: auto;
}
